import React from "react";

export const Impressum = () => {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <h1 className="text-3xl leading-9 font-extrabold text-gray-900">
        Impressum
      </h1>
      <p className="mt-2 text-base leading-6 text-gray-500">
        Tech Vikings UG
        <br />
        Paulstrasse 11
        <br />
        10557 Berlin
      </p>
      <p className="mt-2 text-base leading-6 text-gray-500">
        E-Mail: <a href="mailto:support@tagrush.io">support@tagrush.io</a>
        <br />
      </p>
      <p className="mt-2 text-base leading-6 text-gray-500">
        <strong>Vertreten durch:</strong>
        <br />
        Alexander Braunreuther
      </p>
      <p className="mt-2 text-base leading-6 text-gray-500">
        <strong>Registereintrag:</strong>
        <br />
        Eingetragen im Handelsregister.
        <br />
        Registergericht: Amtsgericht Charlottenburg (Berlin)
        <br />
        Registernummer: HRB 195344 B
      </p>
      <br />
      <h2 className="mt-4 text-2xl leading-9 font-bold text-gray-900">
        Hinweis gem&auml;&szlig; Online-Streitbeilegungs-Verordnung
      </h2>
      <p className="mt-2 text-base leading-6 text-gray-500">
        Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz
        der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für
        die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein
        Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist
        die Europäische Kommission zuständig. Die Europäische
        Online-Streitbeilegungs-Plattform ist hier zu finden:
        <a href="http://ec.europa.eu/odr" target="blank" rel="nofollow">
          http://ec.europa.eu/odr
        </a>
        . Unsere E-Mail lautet:{" "}
        <a href="mailto:support@tagrush.io">support@tagrush.io</a>
      </p>
      <p className="mt-2 text-base leading-6 text-gray-500">
        Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am
        Streitbeilegungsverfahren im Rahmen der Europ&auml;ischen
        Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur
        Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.
      </p>
      <br />
      <br />
      <h2 className="mt-4 text-2xl leading-9 font-bold text-gray-900">
        Disclaimer – rechtliche Hinweise
      </h2>
      <p className="mt-2 text-base leading-6 text-gray-500">
        § 1 Warnhinweis zu Inhalten
        <br />
        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit
        größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt
        jedoch keine Gewähr für die Richtigkeit und Aktualität der
        bereitgestellten kostenlosen und frei zugänglichen journalistischen
        Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die
        Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters
        wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen
        Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem
        Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des
        Anbieters.
        <br />
        <br />
        § 2 Externe Links <br />
        Diese Website enthält Verknüpfungen zu Websites Dritter (&quot;externe
        Links&quot;). Diese Websites unterliegen der Haftung der jeweiligen
        Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen
        Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
        bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der
        Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige
        Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von
        externen Links bedeutet nicht, dass sich der Anbieter die hinter dem
        Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige
        Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise
        auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen
        werden jedoch derartige externe Links unverzüglich gelöscht.
        <br />
        <br />
        § 3 Urheber- und Leistungsschutzrechte <br />
        Die auf dieser Website veröffentlichten Inhalte unterliegen dem
        deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber-
        und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der
        vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
        Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
        Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
        von Inhalten in Datenbanken oder anderen elektronischen Medien und
        Systemen. Inhalte und Rechte Dritter sind dabei als solche
        gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
        einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
        strafbar. Lediglich die Herstellung von Kopien und Downloads für den
        persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt. Die
        Darstellung dieser Website in fremden Frames ist nur mit schriftlicher
        Erlaubnis zulässig.
        <br />
        <br />
        Die Darstellung dieser Website in fremden Frames ist nur mit
        schriftlicher Erlaubnis zulässig.
        <br />
        <br />
        § 4 Besondere Nutzungsbedingungen <br />
        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von
        den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
        ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
        Einzelfall die besonderen Nutzungsbedingungen.
      </p>

      <p className="mt-2 text-base leading-6 text-gray-500">
        Quelle:
        <a href="https://www.juraforum.de/impressum-generator/">
          Impressum Vorlage von JuraForum.de
        </a>
      </p>
    </div>
  );
};
