import React from "react";
import { Link } from "gatsby";

export const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
          <div className="px-5 py-2">
            <Link
              className="text-base leading-6 text-gray-500 hover:text-gray-900"
              to="/impressum/"
            >
              Impressum
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              className="text-base leading-6 text-gray-500 hover:text-gray-900"
              to="/privacy-policy/"
            >
              Privacy
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              className="text-base leading-6 text-gray-500 hover:text-gray-900"
              to="/terms/"
            >
              Terms
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              className="text-base leading-6 text-indigo-600 hover:text-gray-900"
              to="https://bit.ly/2UYGEyk"
            >
              Calculate Lost Retargeting Revenue by Ad Blockers 👻
            </Link>
          </div>
        </nav>
        <p className="mt-8 text-center text-base leading-6 text-gray-400">
          &copy; 2020 Tagrush. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
