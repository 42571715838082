import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

import { Impressum } from "../components/sections/Impressum";
import { Footer } from "../components/sections/Footer";

const ImpressumPage = () => {
  return (
    <Layout>
      <SEO title="Impressum - Boost your ROAS" />

      {/* <Navigation /> */}
      <Impressum />
      <Footer />
    </Layout>
  );
};

export default ImpressumPage;
